import React from "react";
import Styles from "./styles";
import Icons from "../../../images/icons";
import Images from "../../../images";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useIntl } from "gatsby-plugin-react-intl";
import useHand from "./useHand";

const {
    Wrapper,
    Row,
    ArrowLeft,
    ArrowRight,
    DragableHand,
    DragableHandImg,
    TextInfo,
} = Styles;

const { arrow } = Icons;
const { hand } = Images;

const Hand = () => {
    const intl = useIntl();
    const { handRef } = useHand();

    return (
        <Wrapper>
            <Row>
                <ArrowLeft src={arrow} />
                <Droppable droppableId="kiosk">
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef}>
                            <Draggable draggableId="hand" index={1}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <DragableHand ref={handRef}>
                                            <DragableHandImg src={hand} />
                                        </DragableHand>
                                    </div>
                                )}
                            </Draggable>
                        </div>
                    )}
                </Droppable>
                <ArrowRight src={arrow} />
            </Row>
            <Row>
                <TextInfo>{intl.formatMessage({ id: "scanGuide" })}</TextInfo>
            </Row>
        </Wrapper>
    );
};

export default Hand;
