import React from "react";
import Styles from "./styled";
import Icons from "../../images/icons";
import { GatsbyImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";
import { FloatTextData, DescTextData } from "./data";
import Hand from "./Hand";
import Indicator from "./Indicator";
import KioskScreen from "./KioskScreen";
import useLandingQuery from "./useLandingQuery";
import HoverableArea from "./HoverableArea";
import { DragDropContext } from "react-beautiful-dnd";
import useLanding from "./useLanding";

const {
    LandingWrapper,
    TextSectionWrapper,
    KioskSectionWrapper,
    H1Title,
    PDesc,
    BackgroundContainer,
    AnimationContainer,
    Border,
    FloatText,
    DescText,
} = Styles;
const {} = Icons;

const Landing = () => {
    const intl = useIntl();
    const { allFile } = useLandingQuery();
    const { dragStart, dragEnd } = useLanding();

    return (
        <DragDropContext onDragStart={dragStart} onDragEnd={dragEnd}>
            <BackgroundContainer>
                <GatsbyImage
                    style={{ width: "100%" }}
                    imgStyle={{ objectFit: "fill", width: "100%" }}
                    image={
                        allFile.edges[1].node.childImageSharp.gatsbyImageData
                    }
                    alt="landing background"
                />
            </BackgroundContainer>
            <LandingWrapper>
                <TextSectionWrapper>
                    <H1Title>
                        {intl.formatMessage({ id: "landingTitle" })}
                    </H1Title>
                    <PDesc>
                        {intl.formatMessage({ id: "landingDescription" })}
                    </PDesc>
                    <Border />
                    {FloatTextData.map((t) => (
                        <FloatText key={t.id} floatTextData={t}>
                            {intl.formatMessage({ id: t.text })}
                        </FloatText>
                    ))}
                </TextSectionWrapper>
                <KioskSectionWrapper>
                    <AnimationContainer>
                        <KioskScreen />
                    </AnimationContainer>
                    <GatsbyImage
                        style={{
                            width: "100%",
                            maxHeight: "900px",
                        }}
                        imgStyle={{ objectFit: "contain", width: "100%" }}
                        image={
                            allFile.edges[0].node.childImageSharp
                                .gatsbyImageData
                        }
                        alt="landing background"
                    />
                    {DescTextData.map((t) => (
                        <DescText key={t.id} descTextData={t}>
                            {intl.formatMessage({ id: t.text })}
                        </DescText>
                    ))}
                    <Indicator />
                    <HoverableArea />
                </KioskSectionWrapper>
                <Hand />
            </LandingWrapper>
        </DragDropContext>
    );
};

export default Landing;
