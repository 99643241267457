import getReward from "./getReward.svg";
import icon30 from "./icon30.svg";
import icon50 from "./icon50.svg";
import icon80 from "./icon80.svg";
import landing from "./landing.svg";
import progress30 from "./progress30.svg";
import progress50 from "./progress50.svg";
import progress80 from "./progress80.svg";
import progress100 from "./progress100.svg";
import win from "./win.svg";

const Images = {
    getReward,
    icon30,
    icon50,
    icon80,
    landing,
    progress30,
    progress50,
    progress80,
    progress100,
    win,
};

export default Images;
