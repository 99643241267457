import styled, { css } from "styled-components";
import { floating } from "./keyframes";
import { FadeIn } from "./ProgressPyramid/keyframes";
import { IPText } from "./types";

const screenStyles = css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Styles = {
    AnimationContent: styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transform: rotateY(-5deg);
        background: linear-gradient(#fff1e6, #e5e5e5);
        overflow: hidden;
    `,
    Landing: styled.div`
        ${screenStyles};
        & > p {
            animation: ${FadeIn} 0.9s ease-in-out,
                ${floating} 5s infinite ease-in-out;
            animation-delay: 0s, 1s;
        }
        & > img {
            animation: ${FadeIn} 0.9s ease-in-out,
                ${floating} 3s infinite ease-in-out;
            animation-delay: 0s, 1s;
        }
    `,
    Reward: styled.div`
        ${screenStyles};
        & > p {
            animation: ${FadeIn} 0.9s ease-in-out,
                ${floating} 5s infinite ease-in-out;
            animation-delay: 0s, 1s;
        }
        & > img {
            animation: ${FadeIn} 0.9s ease-in-out,
                ${floating} 3s infinite ease-in-out;
            animation-delay: 0s, 1s;
        }
    `,
    PText: styled.p<IPText>`
        position: absolute;
        margin: 0;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: black;
        width: ${({ width }) => width};
        top: ${({ top }) => top};
        @media (max-width: 680px) {
            font-size: 2.1vw;
        }
    `,
    RewardImg: styled.img`
        position: absolute;
        top: 20%;
        width: 70%;
    `,
    LandingImg: styled.img`
        position: absolute;
        top: 45%;
        width: 70%;
    `,
};

export default Styles;
