import styled from "styled-components";

const Styles = {
    Container: styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    Wrapper: styled.div`
        width: 300px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 20px 20px 15px;
        @media (max-width: 1220px) {
            margin-bottom: 50px;
        }
        @media (max-width: 500px) {
            width: 100%;
            margin-left: 30px;
            margin-right: 15px;
        }
    `,
    DescWrapper: styled.div`
        display: flex;
        flex-direction: column;
        z-index: 25;
    `,
    H3Title: styled.h3`
        color: ${(props) => props.theme.color.violet};
        font-size: 16px;
        font-weight: 700;
        margin: 20px 0 0 0;
    `,
    CardText: styled.p`
        font-size: 16px;
        color: black;
    `,
    Border: styled.div`
        position: absolute;
        top: 0;
        left: 0;
        border: 1px gray solid;
        border-top-left-radius: 70px;
        border-bottom-right-radius: 70px;
        z-index: 20;
        width: 100%;
        height: 100%;
    `,
    Background: styled.div`
        position: absolute;
        z-index: 15;
        left: -15px;
        top: 15px;
        background-color: ${(props) => props.theme.color.turquoise};
        border-top-left-radius: 70px;
        border-bottom-right-radius: 70px;
        width: 100%;
        height: 100%;
    `,
};

export default Styles;
