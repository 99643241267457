import styled from "styled-components";
import { IPyramidSegment, IPText } from "./types";
import { FadeIn, BounceIn, FireworksIdle, Floating } from "./keyframes";

const Styles = {
    PyramidSegment: styled.img<IPyramidSegment>`
        visibility: ${({ active }) => (active === true ? "visible" : "hidden")};
        animation: ${FadeIn} 0.3s ease-in-out;
        animation-delay: ${({ animationDelay }) => animationDelay};
        animation-fill-mode: forwards;
        position: absolute;
        bottom: ${({ bottom }) => bottom};
        width: ${({ width }) => width};
        opacity: 0;
    `,
    Face: styled.img`
        animation: ${BounceIn} 0.3s ease-in-out,
            ${Floating} 2s infinite ease-in-out;
        animation-delay: 0.3s, 0.55s;
        animation-fill-mode: forwards, none;
        position: absolute;
        top: 7%;
        width: 70%;
        opacity: 0;
    `,
    Fireworks: styled.img`
        animation: ${FireworksIdle} 1.5s infinite linear;
        position: absolute;
        top: 20%;
        width: 92%;
        z-index: -1;
    `,
    PText: styled.p<IPText>`
        animation: ${BounceIn} 0.3s ease-in-out;
        animation-delay: ${({ animationDelay }) => animationDelay};
        animation-fill-mode: forwards;
        position: absolute;
        margin: 0;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        color: black;
        width: 80%;
        top: ${({ top }) => top};
        opacity: 0;
        z-index: 70;
        @media (max-width: 680px) {
            font-size: 2.2vw;
        }
    `,
};

export default Styles;
