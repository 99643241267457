import { keyframes } from "styled-components";

const floating = keyframes`
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(-5px);
    }
    100%{
        transform: translateY(0px);
    }
`;

export { floating };
