import { useContext } from "react";
import { KioskContext } from "../Layout/context";

const useLanding = () => {
    const { dispatchKioskData } = useContext(KioskContext);

    const dragStart = () => {
        dispatchKioskData({ isHandDragged: true });
    };

    const dragEnd = () => {
        dispatchKioskData({ isHandDragged: false });
        dispatchKioskData({ handDropped: true });
        setTimeout(() => {
            dispatchKioskData({ handDropped: false });
        }, 1000);
    };
    return { dragStart, dragEnd };
};

export default useLanding;
