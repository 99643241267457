const DescTextData = [
  {
    id: 1,
    text: "descText1",
    left: "-27%",
    top: "20%",
  },
  {
    id: 2,
    text: "descText2",
    left: "-27%",
    top: "45%",
  },
  {
    id: 3,
    text: "descText3",
    left: "-27%",
    top: "55%",
  },
];
const FloatTextData = [
  {
    id: 1,
    text: "floatText1",
    left: "0%",
    top: "-8%",
    opacity: ".5",
    size: "30px",
    delay: "-1s",
    showMobile: true,
  },
  {
    id: 2,
    text: "floatText2",
    left: "35%",
    top: "-12%",
    opacity: ".3",
    size: "18px",
    delay: "-2s",
    showMobile: true,
  },
  {
    id: 3,
    text: "floatText3",
    left: "46%",
    top: "-5%",
    opacity: ".7",
    size: "36px",
    delay: "-3s",
    showMobile: true,
  },
  {
    id: 4,
    text: "floatText4",
    left: "0%",
    top: "53%",
    opacity: ".4",
    size: "18px",
    delay: "-4s",
    showMobile: false,
  },
  {
    id: 5,
    text: "floatText5",
    left: "15%",
    top: "63%",
    opacity: ".7",
    size: "34px",
    delay: "-5s",
    showMobile: false,
  },
  {
    id: 6,
    text: "floatText6",
    left: "52%",
    top: "55%",
    opacity: ".7",
    size: "36px",
    delay: "-6s",
    showMobile: false,
  },
];

export { FloatTextData, DescTextData };
