import { useStaticQuery, graphql } from "gatsby";

const useLandingQuery = () => {
    const landingQuery = useStaticQuery(graphql`
        query landingQuery {
            allFile(
                filter: { name: { in: ["landingBackground", "kiosk"] } }
                sort: { fields: name }
            ) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                placeholder: NONE
                            )
                        }
                    }
                }
            }
        }
    `);
    return landingQuery;
};

export default useLandingQuery;
