import styled from "styled-components";

const Styles = {
    DoubleGrid: styled.div`
        position: relative;
        width: 1200px;
        display: grid;
        grid-template-columns: 57% 43%;
        grid-template-rows: 1fr;
        grid-auto-flow: row;
        margin-bottom: 180px;
        @media (max-width: 1230px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
        @media (max-width: 500px) {
            width: 100%;
            margin-bottom: 100px;
        }
    `,
    TripleGrid: styled.div`
        width: 1200px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-auto-flow: row;
        margin-bottom: 100px;
        justify-content: center;
        @media (max-width: 1220px) {
            grid-template-columns: 1fr;
            width: 100%;
        }
        @media (max-width: 500px) {
            width: 100%;
        }
    `,
    ImageWrapper: styled.div`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media (max-width: 1230px) {
            justify-content: center;
        }
        @media (max-width: 500px) {
            margin-left: 15px;
            margin-right: 15px;
            & > img {
                width: 100%;
                height: 100%;
            }
        }
    `,
    SVGImage: styled.img``,
    DescWrapper: styled.div`
        display: flex;
        justify-content: center;
        flex-direction: column;
        @media (max-width: 1230px) {
            margin-top: 50px;
            width: 500px;
        }
        @media (max-width: 500px) {
            width: 100%;
        }
    `,
    H2Title: styled.h2`
        color: ${(props) => props.theme.color.violet};
        font-size: 48px;
        margin: 0;
        margin-left: 15px;
        margin-right: 15px;
        @media (max-width: 500px) {
            font-size: 34px;
        }
    `,
    PText: styled.p`
        font-size: 16px;
        color: black;
        margin-left: 15px;
        margin-right: 15px;
    `,
};

export default Styles;
