import { useContext, useEffect, useRef, useState } from "react";
import { KioskContext } from "../../Layout/context";

const useHand = () => {
    const [GetHandPositionInterval, setGetHandPositionInterval] = useState<any>(
        null
    );
    const handRef = useRef(null);
    const {
        kioskData: { isHandDragged, handDropped },
        dispatchKioskData,
    } = useContext(KioskContext);

    useEffect(() => {
        if (isHandDragged === true) {
            setGetHandPositionInterval(
                setInterval(() => {
                    const { current } = handRef;
                    let tempHandPointAx = current.getBoundingClientRect().x;
                    let tempHandPointAy = current.getBoundingClientRect().y;

                    let tempHandPointBx =
                        current.getBoundingClientRect().x +
                        current.getBoundingClientRect().width;
                    let tempHandPointBy =
                        current.getBoundingClientRect().y +
                        current.getBoundingClientRect().height;

                    dispatchKioskData({ handPointAx: tempHandPointAx });
                    dispatchKioskData({ handPointAy: tempHandPointAy });
                    dispatchKioskData({ handPointBx: tempHandPointBx });
                    dispatchKioskData({ handPointBy: tempHandPointBy });
                }, 500)
            );
        }
    }, [isHandDragged]);

    useEffect(() => {
        clearInterval(GetHandPositionInterval);
    }, [handDropped]);

    return { handRef };
};

export default useHand;
