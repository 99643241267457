import { keyframes } from "styled-components";

const FloatTextAnim = keyframes`
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(10px);
    }
    100%{
        transform: translateY(0);
    }
`;

export { FloatTextAnim };
