import { keyframes } from "styled-components";

const IndicatorAnim = keyframes`
    0%{
        transform: translateX(0);
    }
    50%{
        transform: translateX(10px);
    }
    100%{
        transform: translateX(0);
    }
`;

export { IndicatorAnim };
