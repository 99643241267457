import infoBackground from "./infoBackground.png";
import kioskShadow from "./kioskShadow.svg";
import hand from "./hand.png";

const Images = {
    infoBackground,
    kioskShadow,
    hand,
};

export default Images;
