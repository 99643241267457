import { useContext, useEffect, useState } from "react";
import { KioskContext } from "../../../Layout/context";
import Icons from "../../../../images/kiosk";

const { icon30, icon50, icon80 } = Icons;

const useProgressPyramid = () => {
    const {
        kioskData: { animationProgress },
    } = useContext(KioskContext);

    const [animationProgressData, setAnimationProgressData] = useState<
        boolean[]
    >([]);
    const [facesImages, setFacesImages] = useState<any[]>([]);
    const [precentages, setPrecentage] = useState<string[]>([]);

    useEffect(() => {
        let tempAnimationProgressValues = [false, false, false, false];
        let tempFacesImages = [icon30, icon50, icon80];
        let precentageProgress = ["30%", "50%", "80%", "100%"];
        for (let i = 0; i <= animationProgress; i++) {
            tempAnimationProgressValues[i] = true;
        }
        console.log(tempAnimationProgressValues);
        setAnimationProgressData(tempAnimationProgressValues);
        setFacesImages(tempFacesImages);
        setPrecentage(precentageProgress);
    }, [animationProgress]);

    return {
        facesImages,
        animationProgress,
        precentages,
        animationProgressData,
    };
};

export default useProgressPyramid;
