import { keyframes } from "styled-components";

const Floating = keyframes`
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-10px);
    }
    100%{
        transform: translateY(0);
    }
`;

const FadeIn = keyframes`
    0%{
        opacity: 0;
        transform: translateY(45%);
    }
    100%{
        opacity: 1;
        transform: translateY(0%);
    }
`;

const BounceIn = keyframes`
    0%{
        opacity: 0;
        transform: translateY(0%) scale3d(0.3, 0.3, 0.3);
    }
    50%{
        opacity: 0.5;
        transform: translateY(-75%) scale3d(1.5, 1.5, 1.5);
    }
    100%{
        opacity: 1;
        transform: translateY(0%) scale3d(1, 1, 1);
    }
`;

const FireworksIdle = keyframes`
    0%{
        
        transform: translate(0, -5%) rotateZ(-5deg) scale3d(0.4, 0.4, 0.4);
        opacity: 0;
    }
    50%{
        
        transform: translate(0, 0%) rotateZ(0deg) scale3d(1,1,1);
        opacity: 1;
    }
    100%{
        transform: translate(0, 5%) rotateZ(5deg) scale3d(1.3,1.3,1.3);
        opacity: 0;
    }
`;

export { Floating, FadeIn, BounceIn, FireworksIdle };
