import { useContext, useEffect, useState } from "react";
import { KioskContext } from "../../Layout/context";

const useKioskScreen = () => {
    const {
        kioskData: { isKioskHovered, screenType, animationProgress },
        dispatchKioskData,
    } = useContext(KioskContext);

    const [isScreenLoaded, setIsScreenLoaded] = useState<boolean>(false);

    const SwitchToPyramidAnimation = () => {
        dispatchKioskData({ screenType: "pyramid" });
        if (animationProgress <= 2) {
            setTimeout(() => {
                dispatchKioskData({ screenType: "landing" });
                dispatchKioskData({ isAnimationPlaying: false });
            }, 4000);
        }
        if (animationProgress === 3) {
            setTimeout(() => {
                dispatchKioskData({ screenType: "reward" });
            }, 4000);
            setTimeout(() => {
                dispatchKioskData({ screenType: "landing" });
                dispatchKioskData({ isAnimationPlaying: false });
            }, 8000);
        }
    };

    const PlayAnimation = () => {
        if (screenType === "landing") {
            SwitchToPyramidAnimation();
        }
    };

    useEffect(() => {
        if (isScreenLoaded) {
            PlayAnimation();
        }
        setIsScreenLoaded(true);
    }, [isKioskHovered]);

    return { screenType };
};

export default useKioskScreen;
