import React from "react";
import Styles from "./styles";
import Icons from "../../../images/icons";

const { Wrapper, IndicatorImg } = Styles;

const { indicator } = Icons;

const Indicator = () => {
    return (
        <Wrapper>
            <IndicatorImg src={indicator} />
        </Wrapper>
    );
};

export default Indicator;
