import Icons from "../../images/icons";

const { idea, brainstorming, creativity } = Icons;

const ServiceCardsData = [
    {
        id: 1,
        icon: idea,
        title: "serviceCardTitle1",
        text: "serviceCardText1",
    },
    {
        id: 2,
        icon: brainstorming,
        title: "serviceCardTitle2",
        text: "serviceCardText2",
    },
    {
        id: 3,
        icon: creativity,
        title: "serviceCardTitle3",
        text: "serviceCardText3",
    },
];

export { ServiceCardsData };
