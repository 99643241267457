import styled from "styled-components";
import { HandAnim } from "./keyframes";
import Images from "../../../images";

const { hand } = Images;

const Styles = {
  Wrapper: styled.div`
    z-index: 60;
    position: absolute;
    bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* @media (max-width: 670px) {
            bottom: 2vw;
        } */
  `,
  Row: styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 15px;
    @media (max-width: 1200px) {
      margin-right: 57%;
    }
  `,
  ArrowLeft: styled.img`
    transform: scaleX(-1);
    margin-right: 40px;
    @media (max-width: 670px) {
      margin-right: 15px;
      max-width: 25px;
    }
  `,
  ArrowRight: styled.img`
    margin-left: 40px;
    @media (max-width: 670px) {
      margin-left: 15px;
      max-width: 25px;
    }
  `,
  DragableHand: styled.div`
    position: relative;
    z-index: 500;
  `,
  DragableHandImg: styled.img`
    animation: ${HandAnim} 2s infinite ease-in-out;

    @media (max-width: 670px) {
      max-width: 40px;
    }
  `,
  Mask: styled.div`
    position: absolute;
    left: 75px;
    top: 0px;
    width: 55px;
    height: 120px;
    z-index: 600;
    opacity: 0;
    touch-action: none;
    &:hover {
      cursor: pointer;
    }
    &:active {
      cursor: grab;
    }
    @media (max-width: 670px) {
      left: 40px;
      top: 0px;
      width: 40px;
      height: 78px;
    }
  `,
  TestDragableHandImg: styled.div`
    width: 64px;
    height: 105px;
    position: relative;
    background-image: url(${hand});
    background-color: red;
  `,
  TextInfo: styled.p`
    width: 200px;
    font-size: 14px;
    color: black;
    margin: 0;
    text-align: center;
    @media (max-width: 670px) {
      font-size: 8px;
      width: 100px;
    }
  `,
};
export default Styles;
