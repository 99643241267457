import React from "react";
import Styles from "./styles";
import { SVGIcon } from "../../../Styled";
import IServiceCard from "./types";
import { useIntl } from "gatsby-plugin-react-intl";

const {
    Wrapper,
    DescWrapper,
    H3Title,
    CardText,
    Border,
    Background,
    Container,
} = Styles;

const ServiceCard = ({ serviceCardData: { icon, id } }: IServiceCard) => {
    const intl = useIntl();
    return (
        <Container>
            <Wrapper>
                <Border />
                <Background />
                <SVGIcon src={icon} width={"100px"} height={"100px"} />
                <DescWrapper>
                    <H3Title>
                        {intl.formatMessage({ id: "serviceCardTitle" + id })}
                    </H3Title>
                    <CardText>
                        {intl.formatMessage({ id: "serviceCardText" + id })}
                    </CardText>
                </DescWrapper>
            </Wrapper>
        </Container>
    );
};

export default ServiceCard;
