import Icons from "../../../../images/kiosk";
const { progress30, progress50, progress80, progress100 } = Icons;

const pyramidsSegmentsData = [
    {
        id: 1,
        image: progress30,
        alt: "animation segment",
        bottom: "15%",
        width: "80%",
        animationDelay: "0.1s",
    },
    {
        id: 2,
        image: progress50,
        alt: "animation segment",
        bottom: "22%",
        width: "62%",
        animationDelay: "0.3s",
    },
    {
        id: 3,
        image: progress80,
        alt: "animation segment",
        bottom: "29%",
        width: "42%",
        animationDelay: "0.5s",
    },
    {
        id: 4,
        image: progress100,
        alt: "animation segment",
        bottom: "37%",
        width: "18%",
        animationDelay: "0.7s",
    },
];

export { pyramidsSegmentsData };
