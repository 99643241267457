import { keyframes } from "styled-components";

const HandAnim = keyframes`
    0%{
        transform: translateY(5px);
    }
    50%{
        transform: translateY(-5px);
    }
    100%{
        transform: translateY(5px);
    }
`;

export { HandAnim };
