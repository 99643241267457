import React from "react";
import Styles from "./styles";
import Icons from "../../../../images/kiosk";
import { pyramidsSegmentsData } from "./data";
import { useIntl } from "gatsby-plugin-react-intl";
import useProgressPyramid from "./useProgressPyramid";

const { win } = Icons;

const { PyramidSegment, Face, Fireworks, PText } = Styles;

const ProgressPyramid = () => {
    const intl = useIntl();
    const {
        facesImages,
        animationProgress,
        precentages,
        animationProgressData,
    } = useProgressPyramid();

    return (
        <>
            {animationProgress <= 2 && (
                <Face src={facesImages[animationProgress]} alt="smiling face" />
            )}
            {animationProgress == 2 && (
                <PText animationDelay={"0.7s"} top={"40%"}>
                    {intl.formatMessage({ id: "good" })}
                </PText>
            )}
            {animationProgress != 3 && (
                <PText animationDelay={"0.9s"} top={"46%"}>
                    {intl.formatMessage({ id: "tryAgain" })}
                </PText>
            )}
            {animationProgress <= 2 && (
                <PText animationDelay={"1.1s"} top={"52%"}>
                    {precentages[animationProgress]}
                </PText>
            )}

            {animationProgress === 3 && (
                <>
                    <PText animationDelay={"0.9s"} top={"25%"}>
                        {intl.formatMessage({ id: "win" })}
                    </PText>
                    <PText animationDelay={"0.7s"} top={"35%"}>
                        {precentages[animationProgress]}
                    </PText>
                </>
            )}

            {pyramidsSegmentsData.map((s) => (
                <PyramidSegment
                    key={s.id}
                    bottom={s.bottom}
                    width={s.width}
                    src={s.image}
                    alt={s.alt}
                    animationDelay={s.animationDelay}
                    active={animationProgressData[s.id - 1]}
                />
            ))}
            <Fireworks src={win} alt="fireworks" />
        </>
    );
};

export default ProgressPyramid;
