import styled from "styled-components";
import { IndicatorAnim } from "./keyframes";

const Styles = {
    Wrapper: styled.div`
        z-index: 60;
        position: absolute;
        bottom: 250px;
        left: -40px;
        @media (max-width: 1200px) {
            left: -50px;
            bottom: 250px;
        }
        @media (max-width: 720px) {
            left: -70px;
            bottom: 35vw;
        }
    `,
    IndicatorImg: styled.img`
        animation: ${IndicatorAnim} 2s infinite ease-in-out;
        height: 36px;
        @media (max-width: 720px) {
            max-width: 60px;
        }
    `,
};

export default Styles;
