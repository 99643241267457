import React from "react";
import Styles from "./styles";
import Icons from "../../../images/kiosk";
import ProgressPyramid from "./ProgressPyramid";
import { useIntl } from "gatsby-plugin-react-intl";
import useKioskScreen from "./useKioskScreen";

const { getReward, landing } = Icons;

const {
    AnimationContent,
    PText,
    Landing,
    Reward,
    RewardImg,
    LandingImg,
} = Styles;

const KioskScreen = () => {
    const intl = useIntl();
    const { screenType } = useKioskScreen();

    return (
        <AnimationContent>
            {screenType === "landing" && (
                <Landing>
                    <PText width={"75%"} top={"20%"}>
                        {intl.formatMessage({ id: "kioskScanGuide" })}
                    </PText>
                    <LandingImg src={landing} />
                </Landing>
            )}
            {screenType === "pyramid" && <ProgressPyramid />}
            {screenType === "reward" && (
                <Reward>
                    <RewardImg src={getReward} />
                    <PText width={"90%"} top={"63%"}>
                        {intl.formatMessage({ id: "reception" })} <br />{" "}
                        {intl.formatMessage({ id: "reward" })}
                    </PText>
                </Reward>
            )}
        </AnimationContent>
    );
};

export default KioskScreen;
