import React from "react";
import useHoverableArea from "./useHoverableArea";
import Styles from "./styles";
import { Droppable } from "react-beautiful-dnd";

const { Hoverable } = Styles;

const HoverableArea = () => {
    const { HandleOnDragOver, hoverableAreaRef } = useHoverableArea();
    return (
        <div onMouseOver={HandleOnDragOver}>
            <Droppable droppableId="kiosk">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                        <Hoverable
                            ref={hoverableAreaRef}
                            draggable={true}
                            onDragOver={HandleOnDragOver}
                        />
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default HoverableArea;
