import React from "react";
import Styles from "./styled";
import ServiceCard from "./ServiceCard";
import { useIntl } from "gatsby-plugin-react-intl";
import { Element } from "react-scroll";
import { ServiceCardsData } from "./data";
import Icons from "../../images/icons";

const { arrows } = Icons;
const {
    DoubleGrid,
    TripleGrid,
    DescWrapper,
    ImageWrapper,
    SVGImage,
    H2Title,
    PText,
} = Styles;

const Services = () => {
    const intl = useIntl();
    return (
        <Element name="services">
            <DoubleGrid>
                <ImageWrapper>
                    <SVGImage src={arrows} />
                </ImageWrapper>
                <DescWrapper>
                    <H2Title>
                        {intl.formatMessage({ id: "servicesTitle" })}
                    </H2Title>
                    <PText>
                        {intl.formatMessage({ id: "servicesDescription" })}
                    </PText>
                </DescWrapper>
            </DoubleGrid>
            <TripleGrid>
                {ServiceCardsData.map((s) => (
                    <ServiceCard key={s.id} serviceCardData={s} />
                ))}
            </TripleGrid>
        </Element>
    );
};

export default Services;
