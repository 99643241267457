import styled from "styled-components";

const Styles = {
    Hoverable: styled.div`
        position: absolute;
        width: 25%;
        height: 10%;
        left: 25%;
        bottom: 25%;
        z-index: 100;
    `,
};

export default Styles;
