import { SyntheticEvent, useContext, useEffect, useRef } from "react";
import { KioskContext } from "../../Layout/context";

const useHoverableArea = () => {
    const hoverableAreaRef = useRef(null);

    const {
        kioskData: {
            isHandDragged,
            handPointAx,
            handPointAy,
            handPointBx,
            handPointBy,
            isAnimationPlaying,
        },
        dispatchKioskData,
    } = useContext(KioskContext);

    const TriggerAnimation = () => {
        if (isHandDragged === true && isAnimationPlaying === false) {
            dispatchKioskData({ isKioskHovered: true });
            let animationProgress = Math.floor(Math.random() * 4);
            console.log(animationProgress);
            dispatchKioskData({ animationProgress: animationProgress });
            dispatchKioskData({ isAnimationPlaying: true });
            setTimeout(
                () => dispatchKioskData({ isKioskHovered: false }),
                1000
            );
        }
        return false;
    };

    const HandleOnDragOver = (e: SyntheticEvent) => {
        TriggerAnimation();
    };

    const isOverlaping = () => {
        const { current } = hoverableAreaRef;
        let pointX =
            current.getBoundingClientRect().x +
            current.getBoundingClientRect().width / 2;
        let pointY =
            current.getBoundingClientRect().y +
            current.getBoundingClientRect().height / 2;

        if (
            pointX > handPointAx &&
            pointX < handPointBx &&
            pointY > handPointAy &&
            pointY < handPointBy
        ) {
            TriggerAnimation();
        } else {
        }
    };

    useEffect(() => {
        window.addEventListener("touchmove", isOverlaping);
        return () => {
            window.removeEventListener("touchmove", isOverlaping);
        };
    }, [isOverlaping]);

    return { HandleOnDragOver, hoverableAreaRef };
};

export default useHoverableArea;
