import styled from "styled-components";
import { Container } from "../../Styled";
import { IFloatText, IDescText } from "./types";
import { FloatTextAnim } from "./keyframes";

const Styles = {
  LandingWrapper: styled(Container)`
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0;
    margin-bottom: 400px;
    @media (max-width: 1420px) {
      width: 100%;
    }
    @media (max-width: 1200px) {
      flex-wrap: wrap;
      flex-direction: column;
      margin-bottom: 400px;
    }
    @media (max-width: 500px) {
      margin-bottom: 200px;
    }
  `,
  TextSectionWrapper: styled.div`
    flex-basis: 60%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 160px);
    z-index: 20;
    margin-left: 45px;
    margin-right: 200px;
    margin-top: 130px;
    min-height: 600px;
    @media (max-width: 1200px) {
      margin-left: 25px;
      margin-right: 25px;
      min-height: 600px;
      height: unset;
    }
  `,
  KioskSectionWrapper: styled.div`
    position: relative;
    display: flex;
    flex-basis: 40%;
    height: 850px;
    @media (max-width: 1200px) {
      width: 60%;
      align-self: flex-end;
      top: -100px;
    }
    @media (max-width: 500px) {
      top: -150px;
    }
  `,

  H1Title: styled.h1`
    color: ${(props) => props.theme.color.white};
    width: 115%;
    font-size: 64px;
    font-weight: 700;
    text-shadow: 0px 0px 30px rgba(255, 255, 255, 0.7);
    margin-bottom: 35px;
    /* border: 2px solid black; */
    @media (max-width: 1200px) {
      width: 100%;
    }
    @media (max-width: 900px) {
      font-size: 3rem;
    }
    @media (max-width: 600px) {
      font-size: 2.5rem;
    }
  `,
  PDesc: styled.p`
    font-size: 18px;
    color: ${(props) => props.theme.color.white};
    margin: 0 0 20px 0;
    @media (max-width: 1200px) {
      margin: 0 0 20px 0;
    }
  `,
  BackgroundContainer: styled.div`
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: calc(100vh + 400px);
    display: flex;
    justify-content: center;
    align-content: center;
    @media (max-width: 1200px) {
      height: 2100px;
    }
    @media (max-width: 860px) {
      height: 2000px;
    }
    @media (max-width: 670px) {
      height: 340vw;
    }
    @media (max-width: 400px) {
      height: 400vw;
    }
  `,
  AnimationContainer: styled.div`
    z-index: 47;
    position: absolute;
    left: 24.6%;
    top: 17.4%;
    width: 35%;
    height: 46.3%;
    perspective: 100px;
    perspective-origin: 0% 89%;
    @media (max-width: 1400px) {
      width: 168px;
      left: calc((-85px) - 6vw + 60%);
    }
    @media (max-width: 1200px) {
      width: 172px;
      left: calc((-123px) - 6vw + 60%);
    }
    @media (max-width: 670px) {
      width: 43.2%;
      left: 19%;
      perspective: calc(100vw / 6);
    }
  `,
  Border: styled.div`
    height: 1px;
    width: 100%;
    background-color: black;
  `,
  FloatText: styled.div<IFloatText>`
    position: absolute;
    font-weight: 700;
    animation: ${FloatTextAnim} 10s infinite ease-in-out;
    color: ${(props) => props.theme.color.white};
    left: ${({ floatTextData: { left } }) => left};
    top: ${({ floatTextData: { top } }) => top};
    opacity: ${({ floatTextData: { opacity } }) => opacity};
    font-size: ${({ floatTextData: { size } }) => size};
    animation-delay: ${({ floatTextData: { delay } }) => delay};
    @media (max-width: 500px) {
      font-size: calc(${({ floatTextData: { size } }) => size} / 1.5);
      display: ${({ floatTextData: { showMobile } }) =>
        showMobile ? "static" : "none"};
    }
  `,
  DescText: styled.p<IDescText>`
    position: absolute;
    font-size: 12px;
    color: black;
    border-bottom: 1px black solid;
    margin: 0 0 0 15px;
    left: ${({ descTextData: { left } }) => left};
    top: ${({ descTextData: { top } }) => top};
    @media (max-width: 1200px) {
      left: calc(${({ descTextData: { left } }) => left} * 1.1);
    }
    @media (max-width: 800px) {
      left: calc(${({ descTextData: { left } }) => left} * 1.5);
    }
    @media (max-width: 570px) {
      left: calc(${({ descTextData: { left } }) => left} * 2.5);
    }
  `,
};

export default Styles;
